<script>
export default {
  name: 'AgentCashPlusLogo',

  props: {
    long: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <svg
    v-if="!long"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 360 168"
  >
    <g id="Group 23">
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m95.4 31.1q-4.1 8.6-9.1 20.6-3.2 0.7-9.8 2 11.8-13.7 18.9-22.6zm20.4-29.2q-1.7-0.9-3.2-0.9-1.1 0-1.9 0.6-1.6 1.1-7.6 13-1.1-1.5-2.5-2.4-1.5-0.9-1.7-0.2-2.3 5.3-13 17.8-10.7 12.6-24.9 26.9-22.4 4-32.4 4-4.5 0-5.7-1-0.6-0.5-1.3-0.5-0.8 0-0.8 1.2 0 1.9 1.8 4.7 1.8 2.7 4.6 3.8 3.4 1.1 8.8 1.1 5.2 0 12.5-1.1-15.2 14.5-27.2 23.6-12.1 9.2-17.2 9.2-1 0-1.6-0.4-1.5-0.8-1.5 1.2 0 2.1 1.5 5.7 1.5 3.5 4 6.2 2.5 2.6 5.3 2.6 0.6 0 1-0.1 4.4-0.7 19.5-15.7 15.2-14.9 33.8-35.6 9.4-2.2 16.1-4.3-3.6 8.8-5.7 14.6-2 5.8-1.8 6.7 1.3 3.7 3.5 5.4 2.1 1.7 4.1 1.7 1 0 1.6-0.4 0.6-0.5 0.4-1-0.2-0.6-0.2-1.1 0-5.9 10.2-29.6 8.1-2.7 13.6-5 5.5-2.3 6.9-3.6 2-2 2-3.5 0-0.6-0.4-1-0.4-0.3-1.1-0.1-2.5 1.3-17.3 4.7 5.7-12.8 11.8-25.4 6.2-12.7 9-17.7 0.5-0.8-0.4-2-0.9-1.2-2.6-2.1z"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m135.7 46.6q-6 6-13.1 12.1-7 6.1-12.3 9.3 2.3-5.9 6.6-12.3 4.4-6.4 9-10.6 4.5-4.3 6.9-4.4 1.5 0.5 2.2 2.1 0.8 1.5 0.7 3.8zm34.3 0.5q0-1.3-1.5-2.9-1.5-1.5-2.4-1-5.1 3.1-13.2 9.7-8.1 6.6-17.2 14.9 3.3-7.3 5.9-13.5 2.6-6.3 2.6-6.6-0.2-0.8-0.9-1.8-0.7-1.1-1.8-1.8 0.2-3.8-0.7-7.3-0.9-3.6-3.6-4.7-1.5-0.6-2.4-0.7-0.3-0.2-0.9-0.4-3.6-0.4-11.4 6.5-7.7 6.9-13.9 16.2-6.1 9.3-6.1 15.6 0 3.3 3.3 7.6 1.8 2.5 3.1 2.5 1.5 0.1 7.9-5.6 6.4-5.6 14.4-13.7-5.4 11.6-11.2 22.7-8.1 7.8-13.7 14.1-5.7 6.3-7.2 9-0.4 0.9 0.7 2.6 1.1 1.7 2.9 3.3 1.2 1.4 3 2.5 1.8 1 2.8 0.6 4-1.5 12.6-17.1 34.5-39.6 48.5-50 0.4-0.3 0.4-0.7z"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m163.8 59.8q3.4-5.7 9.4-11.2 6-5.4 10.9-8-2.3 4.3-8.2 9.8-5.8 5.4-12.1 9.4zm42.2-13.1q0-1.5-0.7-2.4-0.8-0.9-1.3-0.5l-2.9 2.6q-11 9.8-21.4 17.3-10.3 7.6-18.8 8.4-0.4-1.6-0.4-3 0-0.6 0.2-1.8h1.1q1.9-0.2 8.8-5.4 7-5.2 12.7-11.3 5.7-6.1 5.7-10.1 0-0.7-0.1-1-0.4-1.5-1-2.7-1.4-3.8-4.3-3.8-5.8 0-12.5 5.7-6.8 5.8-11.4 13.9-4.7 8.1-4.7 14.6 0 2.7 0.9 4.8 0.7 1.6 1.5 2.6 1.3 2.1 3.5 3.8 2.2 1.6 4.5 1.6 4.8 0 13.8-6.4 9.1-6.4 17.2-14.3 8.2-7.9 9.2-10.6 0.4-1 0.4-2z"
      />
      <path
        fill="currentColor"
        d="m235.1 72.9q-7.2 7.2-9.3 7.1-3.4-0.3-5.4-2.3-2.4-1.5-3.3-3.8-0.9-1.8-0.9-4.4 0-6.9 5.9-18.8-24.7 27.3-26 27.7-1.1 0.3-2 0.3-1.2 0-2.2-0.5-1.7-0.7-3-2-1.3-1.3-0.7-2.3 1.6-2.7 5.5-10.4 4-7.7 7.1-14.6 3.1-6.9 3.1-8.6 0-1.1 1.1-1.1 1.5 0 3.2 1.6 1.6 1.5 1.8 3.8 0.1 1-1.4 5.2-1.4 4.2-3.7 9.4 11.6-11 18.6-17.4 7-6.5 8.4-6.8h0.4q1.4-0.2 2.9 0.6 1.4 0.8 2.4 2.1 1 1.3 1 2.4 0 0.3-0.2 0.8-0.6 1-1.3 2.2-0.7 1.2-1.5 2.5-5.1 8.5-8.7 15.4-3.5 6.9-4.2 11.5 5.4-3.1 12.8-10.6 7.5-7.5 16.2-16.9l1.8-2q0.7-0.8 1.5 1 0.7 1.8 1 3.8 0.1 1.2-6.8 9.5-6.8 8.3-14.1 15.6"
      />
      <path
        fill="currentColor"
        d="m298 22.7q1 1.5 1 2.5 0 0.8-0.6 0.5-1.8-0.7-5.9-0.7-6.5 0-17.8 2.1l-3 4.9q-8.3 13.8-13.4 23.1-5.2 9.3-7 15.9 7.7-4.7 18.6-13.8 11-9.1 16.3-13.8 0.8-0.8 1.5 1 0.8 1.8 1 3.9 0.1 1.1-8.5 9.2-8.7 8.2-17.7 15.4-9 7.2-11.1 7.1-1.5-0.1-3.1-1.3-1.6-1.2-2.7-2.6-3.5-4-3.8-8.2-0.3-2.9 3.9-10.5 4.3-7.6 13.1-21.9l3.3-5.5q-13.9 3.9-21.4 7.7-0.9 0.4-1.3-0.4-0.4-0.8-0.4-2.3 0-2 0.8-4.1 0.8-2 2.2-2.9 1.7-1 9.3-3 7.7-2.1 16.2-3.7 6.9-11.8 6.6-14.5-0.2-1.8 1-1.8 1.2 0 2.8 1.2 1.7 1.2 2.9 3 1.3 1.7 1.3 3.4 0 1.4-2.9 6.6 5.4-0.7 8.7-0.7l1.9 0.1q3 0.1 5.1 1.3 2.1 1.3 3.1 2.8"
      />

      <path
        fill="currentColor"
        d="m165.8 132q0 2.1-0.9 3.9-0.8 1.7-9.3 9.7-8.6 8.1-17.5 14.7-8.9 6.7-12.8 6.7-2.9 0-5-2.1-2.3-1.9-4.3-5.6-2-3.6-2.8-9.6-0.2-2-0.2-3 0-11 8.7-25.9 8.7-14.9 20.1-26.7 11.3-11.7 17.6-13 0.3-0.1 0.9-0.1 1.7 0 3.3 1.1 1.5 1.2 2.5 2.8 1.4 0.7 2.8 2.4 1.3 1.6 2 4.7 0.5 1.9-3.1 8.6-3.6 6.8-8.4 13.3-4.9 6.5-8.2 8.3-1.4 0.7-2.8 0.7-1.4 0-2.2-0.5-0.9-0.5-0.4-1 2.4-2.2 6.4-8.3 4-6.1 7.5-12.9 3.5-6.9 4.8-11.1-6.1 4.1-16.5 15.8-10.4 11.6-18.3 25.8-7.9 14.1-7.9 26 0 1.6 0.1 2.4 6.2 0.6 17.3-7.8 11-8.3 22.8-19.1 2.3-2.3 2.6-2.4 0.5-0.4 0.8 0.3 0.4 0.7 0.4 1.9"
      />

      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m184.5 125.4q-5.2 6.3-13.5 15.3-8.2 9-14.1 13.5 1.7-6.2 5.7-12.7 3.9-6.4 9.6-11 5.8-4.5 12.3-5.1zm7.6 31.6q6.9-6.6 13.5-14.2 6.5-7.6 6.4-8.6-0.3-2.1-1-3.9-0.7-1.7-1.4-1-1.9 2-7.1 7.4-5.3 5.4-11.5 11.2-6.3 5.8-10.6 8.3 0.1-4 1.7-9.3 1.6-5.2 4.4-10 5-5.3 5.2-6.8 0-1.2-1.5-3.5-1.5-2.2-2.9-2.9-1.2-1.8-3.2-3.3-2-1.5-3.8-1.4-5.9 0.3-13.3 6.6-7.4 6.2-12.5 14.5-5.1 8.2-4.4 13.9 0.4 4.8 1.9 7.4 1.5 2.6 3.1 3.2 0.9 0.4 1.6 0.4 0.7 0.1 6.6-5.3 6-5.4 13-12.2-1 3.7-1 6.2 0 0.8 0.2 1.9 1.3 4.3 3.7 5.8 2.1 2.1 4 2.1 2.1 0.1 8.9-6.5z"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m218.6 139.1q-7.1 1.9-10.5 2.7 6.6-5.9 13.8-12.9-1.7 5.7-3.3 10.2zm-3.3 7.6q-2.6 5.2-5.2 8-2.6 2.9-4.8 2.4-2.2-1.3-3.7-3.4-1.5-2.2-2.3-4.8 5.5-0.2 16-2.2zm28.1-12.6q0.6-2.3 0.6-4.2 0-1.8-0.5-1.2-1.4 1.4-5.8 3.5-4.3 2.1-10.6 4.2 4.7-13.2 4.7-18.1 0.2-1.2-2.2-2.7-2.4-1.6-4.2-1.6-0.9 0-1.1 0.4-2.7 3.6-13 13.3-10.2 9.8-15.7 13.7-1.2-0.3-2.7-0.3-3.1 0-3.4 1.5-0.5 1.5-0.5 3.7 0 4.4 2.5 8.5 2.5 4.1 6.5 6.7 3.9 2.5 7.9 2.5 0.6 0 1.8-0.2 3.3-0.5 5.8-2 2.5-1.4 3.8-3l0.4-0.4q0.5-0.7 2.4-4.7 1.9-4 3.9-9.1 13-3.3 17.5-7.2 1.2-1.1 1.9-3.3z"
      />
      <path
        fill="currentColor"
        d="m297 134.9q0 0.6-0.1 1-0.5 1.7-6.5 8-6.1 6.3-12.4 11.7-6.3 5.3-8.5 5.6-1.5 0.1-2.9-0.9-1.8-1.1-3.3-3.4-1.5-2.4-1.5-5.6 0-3.2 0.7-8.6 0.6-5.5 1.6-10.1-6.5 6.3-14.2 16.5-7.7 10.1-10.1 15.4v0.2q-0.5 0.8-2.6-0.7-2.2-1.4-4-4.2-1.9-2.8-2.2-5.9-0.3-2.9 4.1-10.7 4.4-7.8 12.9-21.7 8-12.9 11.6-19.2 3.5-6.3 3.3-8.5-0.3-1.8 1-1.8 1.1 0 2.7 1.2 1.6 1.1 2.9 2.9 1.2 1.7 1.2 3.4 0 1.6-2.5 6.1-2.5 4.4-8 13.4-9.7 15.8-14.2 24.6 6.2-7.4 12-12.8 5.7-5.3 9-5.9h0.3q0.8 0 1.4 0.3 1.6 0.7 3.3 2.5 1.6 1.7 2.2 3.4 0.1 0.8-0.4 3.1-0.5 2.2-1.6 6.3-0.3 1.1-1.3 4.5-0.9 3.4-1.5 6.2 8.8-4.9 16.8-12.2 8.1-7.2 8.5-7.6 0.7-0.6 1.5 0.7 0.8 1.3 0.8 2.8"
      />
      <path
        fill="currentColor"
        d="m358.3 91q-1.7 1.6-3.8 2.6-6.3 2.8-25.4 5.2-9.6 21.3-11.8 25.2-0.6 1-2.4 1-2.1 0-3.8-1.1-1.7-1.1-1.9-3.3-0.7-3.8 4.6-20.1-10.3 0.8-14.7 0.8-3.3 0-5.7-3.6-2.4-3.5-2.4-6.4 0-2.1 1.2-2.3 6.4-1.3 26.3-2 3.1-8.4 5.8-15.2 2.7-6.8 3.3-7.5 1-0.8 4.1 0.5 3.1 1.4 5.6 3.8 2.6 2.4 2.4 4.8 0 1-5.4 13.4 19.7 0.4 24.3 0.9 1.4 0 1.4 0.8 0 0.9-1.7 2.5"
      />
    </g>
  </svg>

  <svg
    v-else
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 643.19 216"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M200.83,116.83c-0.83,2.7-1.26,5.25-1.26,7.66c0,2.12,0.35,3.98,1.06,5.61c0.56,1.2,1.13,2.19,1.69,2.96
	c0.99,1.62,2.34,3.07,4.07,4.34c1.73,1.27,3.47,1.9,5.24,1.9c3.67,0,8.99-2.47,15.97-7.4c6.98-4.94,13.63-10.44,19.94-16.5
	c0.18-0.18,0.34-0.34,0.52-0.51c-1.02,2.06-2.07,4.14-3.17,6.27c-3,5.82-5.13,9.75-6.4,11.8c-0.42,0.7-0.14,1.55,0.85,2.54
	c0.99,0.99,2.15,1.76,3.49,2.33c0.71,0.35,1.52,0.53,2.43,0.53c0.71,0,1.48-0.11,2.33-0.32c0.99-0.28,10.93-10.76,29.83-31.42
	c-4.51,9.03-6.77,16.11-6.77,21.26c0,1.97,0.35,3.67,1.06,5.08c0.71,1.69,1.97,3.1,3.81,4.23c1.48,1.55,3.53,2.43,6.14,2.64
	c1.62,0.07,5.2-2.63,10.74-8.09c2.35-2.32,4.67-4.73,6.96-7.19c-0.13,0.71-0.19,1.33-0.14,1.85c0.28,3.24,1.73,6.38,4.34,9.41
	c0.85,1.13,1.89,2.15,3.12,3.07c1.23,0.92,2.42,1.41,3.54,1.48c1.62,0.07,5.87-2.68,12.75-8.25c6.88-5.57,13.61-11.5,20.2-17.77
	c6.59-6.28,9.86-9.84,9.78-10.68c-0.21-1.62-0.6-3.12-1.16-4.5c-0.56-1.37-1.13-1.78-1.69-1.22c-4.09,3.67-10.31,9.01-18.67,16.03
	c-8.36,7.02-15.46,12.36-21.31,16.03c1.41-5.15,4.07-11.3,7.99-18.46c3.91-7.16,9.04-16.06,15.39-26.71l3.38-5.71
	c8.6-1.62,15.41-2.43,20.42-2.43c3.1,0,5.36,0.28,6.77,0.85c0.42,0.21,0.63,0,0.63-0.63c0-0.78-0.39-1.74-1.16-2.91
	c-0.78-1.16-1.96-2.22-3.54-3.17c-1.59-0.95-3.54-1.46-5.87-1.53L352,69.17c-2.54,0-5.85,0.28-9.94,0.85
	c2.19-4.02,3.28-6.59,3.28-7.72c0-1.27-0.48-2.59-1.43-3.97c-0.95-1.38-2.06-2.52-3.33-3.44c-1.27-0.92-2.33-1.37-3.17-1.37
	c-0.99,0-1.38,0.7-1.16,2.12c0.21,2.05-2.33,7.62-7.62,16.71c-6.49,1.27-12.64,2.7-18.46,4.28c-5.82,1.59-9.4,2.77-10.74,3.54
	c-1.06,0.71-1.89,1.83-2.49,3.38c-0.6,1.55-0.9,3.1-0.9,4.65c0,1.2,0.16,2.1,0.48,2.7c0.32,0.6,0.79,0.76,1.43,0.48
	c5.71-2.96,13.89-5.92,24.54-8.89l-3.81,6.35c-2.15,3.53-4.1,6.77-5.9,9.78l-1.4,1.54c-6.63,7.12-12.8,13.51-18.51,19.15
	c-5.71,5.64-10.61,9.66-14.7,12.06c0.49-3.53,2.1-7.9,4.81-13.12c2.72-5.22,6.05-11.04,10-17.45c0.56-0.99,1.13-1.92,1.69-2.8
	c0.56-0.88,1.06-1.71,1.48-2.49c0.14-0.42,0.21-0.74,0.21-0.95c0-0.85-0.37-1.74-1.11-2.7c-0.74-0.95-1.68-1.73-2.8-2.33
	c-1.13-0.6-2.22-0.83-3.28-0.69h-0.53c-1.06,0.21-4.27,2.75-9.63,7.62c-5.36,4.87-12.45,11.46-21.26,19.78
	c1.69-3.95,3.1-7.51,4.23-10.68c1.13-3.17,1.66-5.15,1.59-5.92c-0.14-1.69-0.85-3.12-2.12-4.28c-1.27-1.16-2.47-1.75-3.6-1.75
	c-0.85,0-1.27,0.39-1.27,1.16c0,0.63-0.27,1.7-0.8,3.17l-2.9,2.54c-8.46,7.55-16.7,14.23-24.7,20.05c-8,5.82-15.25,9.05-21.74,9.68
	c-0.35-1.27-0.53-2.43-0.53-3.49c0-0.42,0.07-1.13,0.21-2.12h1.27c1.48-0.14,4.9-2.2,10.26-6.19c5.36-3.98,10.24-8.34,14.65-13.06
	c4.41-4.72,6.61-8.6,6.61-11.64c0-0.56-0.03-0.95-0.11-1.16c-0.28-1.13-0.67-2.19-1.16-3.17c-1.06-2.89-2.72-4.34-4.97-4.34
	c-4.44,0-9.27,2.2-14.49,6.61c-2.3,1.94-4.44,4.08-6.42,6.38c-3.76,2.34-8.51,5.85-14.26,10.55c-6.17,5.04-12.75,10.74-19.73,17.08
	c2.54-5.57,4.8-10.74,6.77-15.5c1.97-4.76,2.96-7.25,2.96-7.46c-0.14-0.63-0.48-1.36-1-2.17c-0.53-0.81-1.22-1.5-2.06-2.06
	c0.14-2.89-0.12-5.68-0.79-8.36c-0.67-2.68-2.03-4.44-4.07-5.29c-1.2-0.49-2.15-0.78-2.86-0.85c-0.21-0.14-0.53-0.28-0.95-0.42
	c-2.75-0.35-7.09,2.12-13.01,7.4c-2.28,2.03-4.46,4.2-6.55,6.5c-2.08,0.99-8.67,2.74-19.79,5.25c4.3-9.66,8.81-19.29,13.54-28.88
	c4.72-9.59,8.18-16.29,10.37-20.1c0.35-0.63,0.18-1.41-0.53-2.33c-0.7-0.92-1.71-1.71-3.01-2.38c-1.3-0.67-2.52-1.01-3.65-1.01
	c-0.85,0-1.59,0.21-2.22,0.63c-1.2,0.85-4.09,5.78-8.67,14.81c-0.85-1.13-1.82-2.03-2.91-2.7c-1.09-0.67-1.74-0.76-1.96-0.26
	c-1.76,4.02-6.74,10.79-14.92,20.31s-17.7,19.71-28.56,30.57c-17.21,3.03-29.62,4.55-37.23,4.55c-3.45,0-5.64-0.35-6.56-1.06
	c-0.49-0.42-0.99-0.63-1.48-0.63c-0.63,0-0.95,0.46-0.95,1.37c0,1.48,0.69,3.26,2.06,5.34c1.37,2.08,3.16,3.51,5.34,4.28
	c2.54,0.85,5.89,1.27,10.05,1.27c4.02,0,8.82-0.39,14.39-1.16c-11.64,10.93-22.07,19.87-31.31,26.82
	c-9.24,6.95-15.8,10.42-19.67,10.42c-0.78,0-1.41-0.14-1.9-0.42c-1.13-0.63-1.69-0.18-1.69,1.38c0,1.62,0.58,3.77,1.74,6.45
	c1.16,2.68,2.7,5.03,4.6,7.03c1.9,2.01,3.91,3.01,6.03,3.01c0.49,0,0.88-0.03,1.16-0.11c3.38-0.56,10.88-6.51,22.48-17.82
	c11.6-11.32,24.52-24.84,38.77-40.57c7.19-1.69,13.36-3.31,18.51-4.87c-2.75,6.63-4.92,12.15-6.51,16.55
	c-1.59,4.41-2.27,6.97-2.06,7.67c0.99,2.82,2.29,4.87,3.91,6.14c1.62,1.27,3.21,1.9,4.76,1.9c0.78,0,1.39-0.16,1.85-0.48
	c0.46-0.32,0.62-0.69,0.48-1.11c-0.14-0.42-0.21-0.85-0.21-1.27c0-4.44,3.88-15.65,11.64-33.64c6.21-2.04,11.42-3.95,15.66-5.71
	c0.52-0.22,1.02-0.43,1.49-0.63c-0.81,1.12-1.61,2.26-2.39,3.44c-4.69,7.09-7.03,13.03-7.03,17.82c0,2.54,1.27,5.47,3.81,8.78
	c1.41,1.9,2.61,2.86,3.6,2.86c1.13,0.07,4.13-2.06,8.99-6.4c4.87-4.34,10.37-9.57,16.5-15.71c-4.16,8.81-8.46,17.45-12.9,25.92
	c-6.14,5.99-11.35,11.39-15.66,16.18c-4.3,4.8-7.02,8.25-8.15,10.37c-0.35,0.63-0.11,1.6,0.74,2.91c0.85,1.3,1.97,2.56,3.38,3.76
	c0.85,1.13,1.96,2.08,3.33,2.86c1.37,0.78,2.45,1.02,3.23,0.74c3.03-1.13,7.83-7.65,14.39-19.57
	C177.29,141.38,190.54,126.99,200.83,116.83z M220.68,103.08c4.62-4.2,8.8-7.28,12.53-9.26c-1.76,3.32-4.9,7.07-9.41,11.27
	c-4.51,4.2-9.2,7.85-14.07,10.95C212.41,111.59,216.06,107.27,220.68,103.08z M122.88,105.67c-2.4,0.56-6.14,1.34-11.21,2.33
	c9.03-10.37,16.26-18.93,21.69-25.7C130.25,88.78,126.76,96.57,122.88,105.67z M162.87,115.24c-5.36,4.69-10.05,8.23-14.07,10.63
	c1.69-4.51,4.21-9.2,7.56-14.07c3.35-4.87,6.75-8.92,10.21-12.17c3.45-3.24,6.1-4.9,7.93-4.97c1.13,0.35,1.97,1.13,2.54,2.33
	c0.56,1.2,0.81,2.68,0.74,4.44C173.2,105.95,168.22,110.55,162.87,115.24z M568.79,119.72c-0.34,0.27-3.55,3.19-9.62,8.74
	c-6.07,5.55-12.39,10.22-18.97,13.99c0.41-2.19,0.98-4.59,1.7-7.2c0.72-2.6,1.18-4.32,1.39-5.14c0.89-3.09,1.53-5.48,1.9-7.2
	c0.38-1.71,0.5-2.88,0.36-3.5c-0.41-1.3-1.24-2.62-2.47-3.96s-2.47-2.28-3.7-2.83c-0.48-0.21-0.99-0.31-1.54-0.31h-0.41
	c-2.47,0.41-5.86,2.66-10.18,6.74s-8.81,8.96-13.47,14.65c3.36-6.72,8.71-16.11,16.04-28.18c4.11-6.86,7.11-12,9-15.43
	c1.89-3.43,2.83-5.76,2.83-6.99c0-1.23-0.46-2.52-1.39-3.86c-0.93-1.34-2.01-2.45-3.24-3.34c-1.23-0.89-2.26-1.34-3.08-1.34
	c-0.96,0-1.34,0.68-1.13,2.06c0.21,1.64-1.03,4.88-3.7,9.72c-2.67,4.83-7.03,12.19-13.06,22.06c-2.14,3.5-4.08,6.72-5.87,9.72
	c-1.18,0.97-3.07,2.09-5.69,3.39c-3.26,1.61-7.25,3.24-11.98,4.88c3.5-10.22,5.24-17.21,5.24-20.98c0.21-0.89-0.6-1.94-2.42-3.14
	c-1.82-1.2-3.41-1.8-4.78-1.8c-0.62,0-1.03,0.17-1.23,0.51c-1.99,2.74-6.84,7.87-14.55,15.38c-7.71,7.51-13.63,12.77-17.74,15.79
	c-0.89-0.21-1.89-0.31-2.98-0.31c-2.33,0-3.63,0.58-3.91,1.75c-0.34,1.1-0.51,2.5-0.51,4.22c0,1.04,0.1,2.08,0.28,3.11
	c-3.96,3.48-7.38,6.05-10.26,7.69c0.14-3.08,0.81-6.63,2.01-10.64c1.2-4.01,2.9-7.83,5.09-11.47c3.84-4.11,5.83-6.72,5.97-7.82
	c0.07-0.96-0.48-2.3-1.65-4.01c-1.17-1.71-2.3-2.85-3.39-3.39c-0.96-1.37-2.19-2.62-3.7-3.75c-1.51-1.13-2.98-1.66-4.42-1.59
	c-4.52,0.21-9.65,2.71-15.38,7.51c-3.68,3.08-6.98,6.48-9.91,10.19c-7.71,6.85-15.19,13.02-22.44,18.5
	c-8.43,6.38-15.05,9.32-19.85,8.84c-0.07-0.62-0.1-1.51-0.1-2.67c0-9.05,3.03-18.97,9.1-29.77c6.07-10.8,13.08-20.62,21.03-29.47
	c7.95-8.84,14.26-14.84,18.92-18c-0.96,3.22-2.79,7.44-5.5,12.65c-2.71,5.21-5.59,10.13-8.64,14.76c-3.05,4.63-5.5,7.8-7.35,9.51
	c-0.34,0.34-0.19,0.7,0.46,1.08c0.65,0.38,1.49,0.57,2.52,0.57c1.1,0,2.16-0.27,3.19-0.82c2.54-1.3,5.67-4.44,9.41-9.41
	c3.74-4.97,6.98-10.03,9.72-15.17c2.74-5.14,3.94-8.43,3.6-9.87c-0.55-2.33-1.34-4.13-2.36-5.4c-1.03-1.27-2.09-2.18-3.19-2.72
	c-0.75-1.23-1.73-2.28-2.93-3.14c-1.2-0.86-2.45-1.29-3.75-1.29c-0.48,0-0.82,0.03-1.03,0.1c-4.8,1.03-11.55,6-20.26,14.91
	c-8.71,8.91-16.39,19.04-23.04,30.39c-6.65,11.35-9.98,21.24-9.98,29.67c0,0.75,0.07,1.89,0.21,3.39c0.62,4.53,1.7,8.19,3.24,11
	c1.54,2.81,3.21,4.94,4.99,6.38c1.58,1.58,3.46,2.36,5.66,2.36c3.02,0,7.94-2.54,14.76-7.61c4.84-3.6,9.61-7.48,14.31-11.62
	c-0.43,1.88-0.56,3.63-0.38,5.24c0.34,3.7,1.1,6.55,2.26,8.54c1.17,1.99,2.36,3.19,3.6,3.6c0.69,0.34,1.3,0.51,1.85,0.51
	c0.48,0.07,3.02-1.95,7.61-6.07c4.59-4.11,9.6-8.78,15.02-13.99c-0.75,2.81-1.13,5.18-1.13,7.1c0,0.62,0.07,1.34,0.21,2.16
	c1.03,3.29,2.47,5.52,4.32,6.69c1.58,1.58,3.12,2.37,4.63,2.37c1.58,0.07,5.02-2.42,10.34-7.46c0.08-0.08,0.16-0.16,0.25-0.24
	c1.71,2.44,3.75,4.49,6.18,6.1c2.95,1.95,5.93,2.93,8.95,2.93c0.41,0,1.1-0.07,2.06-0.21c2.47-0.41,4.63-1.18,6.48-2.31
	c1.85-1.13,3.29-2.31,4.32-3.55l0.41-0.41c0.41-0.55,1.32-2.37,2.73-5.45c1.4-3.08,2.86-6.58,4.37-10.49
	c5.5-1.43,9.99-2.94,13.47-4.53c-0.36,0.63-0.71,1.26-1.03,1.85c-3.29,5.97-4.83,10.04-4.63,12.24c0.21,2.4,1.01,4.66,2.42,6.79
	s2.91,3.75,4.52,4.88c1.61,1.13,2.59,1.39,2.93,0.77l0.1-0.21c1.78-4.05,5.57-9.96,11.36-17.74c5.79-7.78,11.16-14.07,16.09-18.87
	c-0.75,3.57-1.39,7.42-1.9,11.57c-0.51,4.15-0.77,7.46-0.77,9.92c0,2.4,0.57,4.51,1.7,6.33c1.13,1.82,2.38,3.14,3.75,3.96
	c1.03,0.75,2.13,1.1,3.29,1.03c1.65-0.21,4.85-2.37,9.62-6.48c4.77-4.11,9.41-8.57,13.93-13.37c4.52-4.8,6.99-7.85,7.4-9.15
	c0.07-0.27,0.1-0.65,0.1-1.13c0-1.17-0.31-2.25-0.93-3.24C569.82,119.5,569.27,119.24,568.79,119.72z M428.83,130.83
	c-6.38,6.86-11.83,12-16.35,15.43c1.3-4.73,3.48-9.56,6.53-14.5c3.05-4.94,6.79-9.14,11.21-12.6c4.42-3.46,9.14-5.43,14.14-5.91
	C440.38,118.11,435.21,123.97,428.83,130.83z M473.31,147.59c-1.95,2.19-3.75,3.08-5.4,2.67c-1.64-0.96-3.03-2.26-4.17-3.91
	c-1.03-1.49-1.8-3.16-2.35-5c0.19-0.2,0.37-0.4,0.56-0.6c4.08-0.21,9.82-1.04,17.27-2.53C477.23,142.28,475.26,145.4,473.31,147.59z
	M482.92,129.49c-5.35,1.51-9.29,2.54-11.83,3.09c4.94-4.52,10.11-9.5,15.53-14.91C485.39,122.12,484.16,126.06,482.92,129.49z
	M623.71,71.26c0,0.66-0.66,1.61-1.97,2.86c-1.31,1.25-2.76,2.27-4.34,3.06c-4.86,2.1-14.59,4.07-29.17,5.91
	c-7.36,16.43-11.89,26.15-13.6,29.17c-0.39,0.79-1.31,1.18-2.76,1.18c-1.58,0-3.02-0.43-4.34-1.28c-1.31-0.85-2.04-2.14-2.17-3.84
	c-0.53-2.89,1.25-10.64,5.32-23.26c-7.88,0.66-13.53,0.99-16.95,0.99c-2.5,0-4.67-1.38-6.51-4.14c-1.84-2.76-2.76-5.26-2.76-7.49
	c0-1.58,0.46-2.43,1.38-2.56c4.86-1.05,14.91-1.84,30.16-2.36c2.36-6.44,4.6-12.29,6.7-17.54s3.35-8.15,3.74-8.67
	c0.79-0.66,2.36-0.46,4.73,0.59c2.37,1.05,4.53,2.5,6.5,4.34c1.97,1.84,2.89,3.68,2.76,5.52c0,0.79-2.1,5.98-6.31,15.57
	c15.11,0.26,24.44,0.59,27.99,0.99C623.18,70.28,623.71,70.61,623.71,71.26z"
    />
  </svg>
</template>
